import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { AnswerService } from '../answer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>, private dialog: MatDialog, private router: Router, private answerService: AnswerService) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.close();
  }

  public close() {
    this.dialogRef.close();
  }

  public startOver() {
    this.answerService.clearAnswerStore();
    // this.answerService.clearNameStore();
    this.close();
    this.dialog.open(InfoModalComponent, { panelClass: 'custom-dialog', disableClose: true});
    this.router.navigate(['/', 'questions', '1']);
  }
}
