import { Injectable } from '@angular/core';
import { Answer } from '../models/answer.model';
import { GeneralRemarks, OverallConclusion, OverallRecommendation, Observations, Observation } from '../models/report.data';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public generalRemarks = GeneralRemarks;
  public overallConclusion = OverallConclusion;
  public overallRecommendation = OverallRecommendation;
  public observations = Observations;

  constructor() { }

  public replaceName(paragraph: string, respondentName: string) {
    return paragraph.replace(`"&NaamA&"`, respondentName);
  }

  public calculateRiskScore(answers: Answer[]) {
    const scores: number[] = answers.map((answer) => {
      const relevantQuestion = answer.question.possibleAnswers.find(
        (posAnswer) => posAnswer.answer === answer.answer
      );
      return relevantQuestion.riskPoints;
    });
    return scores.reduce((acc, cur) => acc + cur);
  }

  public showObsElement(obsElement: Observation, answers: Answer[]) {
    if (answers.find(a => a.question.id === '1').answer === 'No') {
      if (answers.find(a => a.question.id === '2').answer === 'Yes') {
        return obsElement.id > 15;
      }
    }
    return obsElement.id <= 15;
  }

  public getObservationAdvice(answers: Answer[], observation: Observation, respondentName: string) {
    if (answers.find(a => a.question.id === '1').answer === 'No' && answers.find(a => a.question.id === '2').answer === 'No') {
      return observation.advice.find(a => a.question === '1 & 2');
    }
    for (const advice of observation.advice) {
      const relevantQuestion = answers.find(answer => answer.question.id === advice.question);
      if (relevantQuestion && relevantQuestion.answer === advice.answer) {
        return {
          question: advice.question,
          answer: advice.answer,
          risk: this.replaceName(advice.risk, respondentName),
          obligation: this.replaceName(advice.obligation, respondentName)
        };
      }
    }
    const elseAdvise = observation.advice[observation.advice.length - 1];
    return {
      question: elseAdvise.question,
      answer: elseAdvise.answer,
      risk: this.replaceName(elseAdvise.risk, respondentName),
      obligation: this.replaceName(elseAdvise.risk, respondentName),
    };
  }
}
