import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { InfoModalComponent } from '../info-modal/info-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private dialog: MatDialog) { }

  ngOnInit() { }

  public openInfo() {
    this.dialog.open(InfoModalComponent, { panelClass: 'custom-dialog', disableClose: true});
  }
}
