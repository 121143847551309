import { ReportService } from './../shared/report.service';
import { QuestionService } from './../shared/question.service';
import { BehaviorSubject } from 'rxjs';
import { AnswerService } from './../shared/answer.service';
import {
  GeneralRemarks,
  OverallConclusion,
  OverallRecommendation,
  Observation,
  Observations,
} from './../models/report.data';
import { Component, OnInit } from '@angular/core';
import { Answer } from '../models/answer.model';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  public answers$: BehaviorSubject<Answer[]> = this.answerService.answers$;
  public respondentName$: BehaviorSubject<string> = this.answerService
    .respondentName$;
  public generalRemarks = GeneralRemarks;
  public overallConclusion = OverallConclusion;
  public overallRecommendation = OverallRecommendation;
  public observations = Observations;
  public riskScore = this.calculateRiskScore(this.answers$.getValue());

  constructor(
    private answerService: AnswerService,
    private questionService: QuestionService,
    private reportService: ReportService
  ) { }

  ngOnInit() { }

  public replaceName(paragraph: string, respondentName: string) {
    return this.reportService.replaceName(paragraph, respondentName);
  }

  public calculateRiskScore(answers: Answer[]) {
    return this.reportService.calculateRiskScore(answers);
  }

  public checkTextForDefinitionsAndExamples(text: string) {
    return this.questionService.checkTextForDefinitionsAndExamples(text);
  }

  public useNgFunctionWithinHtml(input, e: any): void {
    this.questionService.useNgFunctionWithinHtml(input, e);
  }

  public showObsElement(obsElement: Observation, answers: Answer[]) {
    return this.reportService.showObsElement(obsElement, answers);
  }

  public getObservationAdvice(answers: Answer[], observation: Observation, respondentName: string) {
    return this.reportService.getObservationAdvice(answers, observation, respondentName);
  }
}
