import { Answer } from './../models/answer.model';
import { AnswerService } from '../shared/answer.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss'],
})
export class QuestionListComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollMe', {read: false, static: false}) private myScrollContainer: ElementRef;

  public answers$: Observable<Answer[]> = this.answerService.answers$;
  public allQuestionsAnswered$: BehaviorSubject<boolean> = this.answerService
    .allQuestionsAnswered$;

  constructor(private answerService: AnswerService) {}

  ngOnInit() {
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
}
