export interface Example {
    title: string;
    text: string;
}

export const Examples: Example[] = [
    {
        title: 'Example 1',
        text: `The taxpayer (head office) has a permanent establishment abroad, in which IP has been developed. The Dutch head office makes a deemed royalty payment for this IP to the foreign PE, which is deductible for Dutch tax purposes. The corresponding royalty revenue is not taken into account in the country of the PE, and is therefore not subject to taxation. Under ATAD2, the deduction will not be permitted in the Netherlands.`
    },
    {
        title: 'Example 2',
        text: `Imagine a situation where an associated (hybrid entity) enterprise outside of the EU makes a payment to a taxpayer. Based on the local tax laws in the foreign country, the payment is considered to have been paid to the taxpayer. The payment is deductible in that country. The taxpayer (partially) allocates the payment to a foreign PE. Now imagine that this payment is not taxed in the country of the PE. In such a case, there is deduction without inclusion due to a hybrid mismatch at the level of the PE. The taxpayer must report income to the amount of the benefit.`
    },
    {
        title: 'Example 3',
        text: `The taxpayer (head office) has developed IP. Based on the legislation of the state where the foreign PE is based, the deemed royalty payment for this IP is deducted from the profit. The Netherlands does not recognise this royalty payment, and therefore does not tax it. Under ATAD2, the Netherlands must now take this revenue into account.`
    },
    {
        title: 'Example 4',
        text: `This could be a payment of interest on a loan concluded by the head office as a means of financing assets of the permanent establishment. Based on Dutch tax law, (a part of) this interest is allocated to the permanent establishment in the Netherlands. If the interest is also fully deductible at the level of the head office, (partial) double deduction may occur.`
    },
    {
        title: 'Example 5',
        text: `The balance sheet of a foreign head office includes a mobile installation. This installation is temporarily made available to a PE in the Netherlands. The remuneration (i.e. the deemed payment) involved, is in principle deductible from the PE profit in the Netherlands. Due to the fact that the remuneration (the deemed payment) is not included in the profit of the head office based on local tax legislation, a mismatch occurs. In this case, the remuneration (deemed payment) is not deductible from the PE profit in the Netherlands.`
    },
    {
        title: 'Example 6',
        text: `A Dutch PE includes an asset on its balance sheet. Another PE in a different country, makes a deemed payment to the Dutch PE for the use of this asset. Under Dutch tax law, this usage, and therefore the deemed payment, is not recognised. If the country of the foreign PE allows deduction of the deemed payment, the Dutch PE must include the revenue as part of its profit (to prevent a deduction without inclusion).`
    },
];
