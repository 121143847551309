import { Question } from '../models/question-list.data';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { QuestionService } from '../shared/question.service';
import { AnswerService } from '../shared/answer.service';
import { Answer } from '../models/answer.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-latest-question',
  templateUrl: './latest-question.component.html',
  styleUrls: ['./latest-question.component.scss']
})
export class LatestQuestionComponent implements OnInit {
  public currentQuestion$ = this.route.data.pipe(pluck('currentQuestion'));
  public answers$: Observable<Answer[]> = this.answerService.answers$;
  public allQuestionsAnswered$ = this.answerService.allQuestionsAnswered$;

  constructor(
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private answerService: AnswerService,
    private router: Router,
  ) { }

  animating = {
    sliding: false
  };

  ngOnInit() {

  }

  public setAnswer(response: string, currentQuestion: Question) {
    this.animating.sliding = true;
    const answer = {
      question: currentQuestion,
      answer: response
    };
    if (this.answerService.answers$.getValue().length > 0) {
      this.answerService.updateAnswerStore(answer);
    } else {
      this.answerService.setAnswerStore(answer);
    }
    const nextQuestionId = this.questionService.getNextQuestionIdFromAnswer(
      answer
    );
    if (nextQuestionId) {
      this.router.navigate(['questions', nextQuestionId]);
    }
  }

  public checkTextForDefinitionsAndExamples(text: string) {
    return this.questionService.checkTextForDefinitionsAndExamples(text);
  }

  public useNgFunctionWithinHtml(input, e: any): void {
    this.questionService.useNgFunctionWithinHtml(input, e);
  }


}
