import { QuestionGuard } from './shared/question-guard';
import { ReportComponent } from './report/report.component';
import { QuestionListComponent } from './question-list/question-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LatestQuestionComponent } from './latest-question/latest-question.component';
import { LatestQuestionResolver } from './latest-question/latest-question.resolver';

const routes: Routes = [
  {
    path: '',
    canActivate: [QuestionGuard],
    children: [
      {
        path: 'report',
        component: ReportComponent
      },
      {
        path: 'questions',
        component: QuestionListComponent,
        resolve: {},
        children: [
          {
            path: ':questionId',
            component: LatestQuestionComponent,
            resolve: {
              currentQuestion: LatestQuestionResolver
            },
            canActivate: [QuestionGuard]
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '1'
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LatestQuestionResolver]
})
export class AppRoutingModule { }
