import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-card-button",
  templateUrl: "./card-button.component.html",
  styleUrls: ["./card-button.component.scss"],
})
export class CardButtonComponent implements OnInit {
  @Input()
  icon: string;
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  disabled = false;

  constructor() {}

  ngOnInit(): void {}

  public onClick(e) {
    if (this.disabled) {
      e.stopPropagation();
    }
  }
}
