import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Definition } from 'src/app/models/terminology.data';

@Component({
  selector: 'app-terminology-modal',
  templateUrl: './terminology-modal.component.html',
  styleUrls: ['./terminology-modal.component.scss']
})
export class TerminologyModalComponent implements OnInit {

  public definition: Definition;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TerminologyModalComponent>
  ) {
    this.definition = data.definition;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public exit() {
    this.dialogRef.close();
  }

}
