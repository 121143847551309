import { Answer } from './../models/answer.model';
import { BehaviorSubject } from 'rxjs';
import { QuestionService } from './question.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {
  public answers$: BehaviorSubject<Answer[]> = new BehaviorSubject<Answer[]>(
    JSON.parse(localStorage.getItem('answers')) || []
  );
  public respondentName$: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('respondentName') || '');

  public questionList = this.questionService.questionList;
  public allQuestionsAnswered$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  constructor(private questionService: QuestionService) {
    this.answers$.subscribe(answers => {
      localStorage.setItem('answers', JSON.stringify(answers));
      const latestAnswer = answers[answers.length - 1];
      if (
        latestAnswer &&
        this.questionService.getNextQuestionIdFromAnswer(latestAnswer) === 'end'
      ) {
        this.allQuestionsAnswered$.next(true);
      } else {
        this.allQuestionsAnswered$.next(false);
      }
    });
    this.respondentName$.subscribe(name => localStorage.setItem('respondentName', name));
  }

  public updateAnswerStore(answer: Answer) {
    const oldAnswers = this.answers$.getValue();
    const newAnswers = [];
    for (const [index, oldAnswer] of oldAnswers.entries()) {
      if (oldAnswer.question === answer.question) {
        break;
      } else {
        newAnswers.push(oldAnswer);
      }
    }
    this.answers$.next([...newAnswers, answer]);
  }

  public setAnswerStore(answer: Answer) {
    this.answers$.next([answer]);
  }

  public clearAnswerStore() {
    this.answers$.next([]);
  }

  public setNameStore(name: string) {
    this.respondentName$.next(name);
  }

  public clearNameStore() {
    this.respondentName$.next('');
  }
}
