export interface Question {
  id: string;
  question: string;
  possibleAnswers: {
    answer: string;
    nextQuestion: string;
    riskPoints: number;
  }[];
}

export const QuestionList: Question[] = [
  {
    id: '1',
    question:
      'Is the taxpayer a legal entity which is a tax resident of the Netherlands or a legal entity incorporated under Dutch law (e.g. BV, NV, Cooperative) that is subject to Dutch corporate income taxation as a Dutch domestic taxpayer?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '3',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '2',
        riskPoints: 0
      }
    ]
  },
  {
    id: '2',
    question:
      'Is the taxpayer a legal entity that is not based in the Netherlands, and that is subject to Dutch corporate income taxation as a non-resident taxpayer due to the existence of a permanent establishment in the Netherlands?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '31',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: 'end',
        riskPoints: -1
      }
    ]
  },
  {
    id: '3',
    question:
      'Is the Dutch domestic taxpayer part of an international structure?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '4',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '28',
        riskPoints: 0
      }
    ]
  },
  {
    id: '4',
    question:
      'Do(es) (one of) the shareholder(s)/participant(s) regard the Dutch domestic taxpayer as transparent for tax purposes based on their local tax law?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '5',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '5',
        riskPoints: 0
      }
    ]
  },
  {
    id: '5',
    question:
      `Does the Dutch domestic taxpayer provide remunerations or payments (which are in principle deductible in the Netherlands) to an associated enterprise (referred to as 'recipient' in the following questions)?`,
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '6',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '15',
        riskPoints: 0
      }
    ]
  },
  {
    id: '6',
    question:
      'Is at least one of these recipients a hybrid entity?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '7',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '7',
        riskPoints: 0
      }
    ]
  },
  {
    id: '7',
    question:
      'Is at least one of the recipients based outside the EU (a non-EU recipient)?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '8',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '9',
        riskPoints: 0
      }
    ]
  },
  {
    id: '8',
    question:
      'Is the corresponding revenue of the non-EU recipient included in the taxable base in the State of the recipient within 12 months from the moment of deduction?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '9',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '9',
        riskPoints: 1
      }
    ]
  },
  {
    id: '9',
    question:
      'Are there remunerations or payments by the Dutch domestic taxpayer to an associated enterprise that are subsequently passed on (directly or indirectly) to (an)other associated enterprise(s)? It is possible that you do not know the answer to this question because this(these) subsequent payment(s) are not apparent to you.If this is the case, please answer YES.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '10',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '12',
        riskPoints: 0
      }
    ]
  },
  {
    id: '10',
    question:
      `Is one of these associated enterprises (one of the (subsequent) recipients) a resident of a non-EU State? It is possible that you do not know the answer to this question because these enterprises are not apparent to you. If this is the case, please answer YES. `,
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '11',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '12',
        riskPoints: 0
      }
    ]
  },
  {
    id: '11',
    question:
      `Is the corresponding revenue of these payments excluded from taxation (no inlusion) at the level of one these non-EU (subsequent) recipient(s)? It is possible that you do not know the answer to this question because this (these) subsequent payment(s) or taxation is not apparent to you. If this is the case, please answer YES. `,
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '12',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '12',
        riskPoints: 0
      }
    ]
  },
  {
    id: '12',
    question:
      'Does the recipient have a permanent establishment?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '13',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '15',
        riskPoints: 0
      }
    ]
  },
  {
    id: '13',
    question:
      'Are there any remunerations or payments that are (partially) attributable to a permanent establishment of the recipient?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '14',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '15',
        riskPoints: 0
      }
    ]
  },
  {
    id: '14',
    question:
      'Is this permanent establishment of the recipient recognised by the State where the PE is based?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '15',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '15',
        riskPoints: 1
      }
    ]
  },
  {
    id: '15',
    question:
      'Does the Dutch domestic taxpayer have a foreign permanent establishment?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '16',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '26',
        riskPoints: 0
      }
    ]
  },
  {
    id: '16',
    question:
      'Does the Dutch domestic taxpayer (also) have a foreign permanent establishment outside the EU?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '17',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '18b',
        riskPoints: 0
      }
    ]
  },
  {
    id: '17',
    question:
      'Does the Dutch domestic taxpayer have multiple permanent establishments outside the EU?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '18a',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '18a',
        riskPoints: 0
      }
    ]
  },
  {
    id: '18a',
    question:
      'Is (Are all) the permanent establishment(s) recognised abroad as a permanent establishment?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '19a',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '19a',
        riskPoints: 1
      }
    ]
  },
  {
    id: '18b',
    question:
      'Is (Are all) the permanent establishment(s) recognised abroad as a permanent establishment?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '19b',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '19b',
        riskPoints: 1
      }
    ]
  },
  {
    id: '19a',
    question:
      'Is there a double deduction of a remuneration, payment, expense or loss? (I.e. a deduction at the level of the Dutch domestic taxpayer (head office) as well as at the level of (one of) the foreign permanent establishment(s).)?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '20a',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '20a',
        riskPoints: 0
      }
    ]
  },
  {
    id: '19b',
    question:
      'Is there a double deduction of a remuneration, payment, expense or loss? (I.e. a deduction at the level of the Dutch domestic taxpayer (head office) as well as at the level of (one of) the foreign permanent establishment(s).)? ',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '20b',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '20b',
        riskPoints: 0
      }
    ]
  },
  {
    id: '20a',
    question:
      'Is there a deemed payment (that is in principle deductible in the Netherlands) to (one of) the foreign permanent establishment(s)?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '21a',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '22a',
        riskPoints: 0
      }
    ]
  },
  {
    id: '20b',
    question:
      'Is there a deemed payment (that is in principle deductible in the Netherlands) to (one of) the foreign permanent establishment(s)?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '21b',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '22b',
        riskPoints: 0
      }
    ]
  },
  {
    id: '21a',
    question:
      'Is that deemed payment (see previous question) disregarded in the State of the permanent establishment? See Example 1.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '22a',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '22a',
        riskPoints: 0
      }
    ]
  },
  {
    id: '21b',
    question:
      'Is that deemed payment (see previous question) disregarded in the State of the permanent establishment? See Example 1.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '22b',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '22b',
        riskPoints: 0
      }
    ]
  },
  {
    id: '22a',
    question:
      'Does the Dutch domestic taxpayer receive remunerations or payments from an associated enterprise based outside the EU, which are in principle deductible in that non-EU State?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '23a',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '24',
        riskPoints: 0
      }
    ]
  },
  {
    id: '22b',
    question:
      'Does the Dutch domestic taxpayer receive remunerations or payments from an associated enterprise based outside the EU, which are in principle deductible in that non-EU State?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '23b',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '26',
        riskPoints: 0
      }
    ]
  },
  {
    id: '23a',
    question:
      'Is this received amount (partially) allocated to a foreign permanent establishment of the Dutch domestic taxpayer (and therefore not fully included in the Dutch tax base)? See Example 2.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '24',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '24',
        riskPoints: 0
      }
    ]
  },
  {
    id: '23b',
    question:
      'Is this received amount (partially) allocated to a foreign permanent establishment of the Dutch domestic taxpayer (and therefore not fully included in the Dutch tax base)? See Example 2.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '26',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '26',
        riskPoints: 0
      }
    ]
  },
  {
    id: '24',
    question:
      'Are there deemed payments by a permanent establishment based outside the EU (which are in principle deductible in that State of residence) to the Dutch domestic taxpayer (Dutch head office)? See Example 3.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '25',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '26',
        riskPoints: 0
      }
    ]
  },
  {
    id: '25',
    question:
      'Is the corresponding revenue of the deemed payment (see previous question) included in the Dutch tax base of the Dutch domestic taxpayer? See Example 3.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '26',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '26',
        riskPoints: 1
      }
    ]
  },
  {
    id: '26',
    question:
      'Is the Dutch domestic taxpayer a participant in a foreign entity which is considered to be hybrid?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '27',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '28',
        riskPoints: 0
      }
    ]
  },
  {
    id: '27',
    question:
      'Does this hybrid entity (see previous question) receive a remuneration or payment from an associated enterprise, which is in principle deductible at the level of this associated enterprise?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '28',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '28',
        riskPoints: 0
      }
    ]
  },
  {
    id: '28',
    question:
      'Has the Dutch domestic taxpayer concluded a so-called structured arrangement with a third party which has resulted in the creation of a double deduction or deduction without inclusion? It is possible that you do not know the answer to this question.If this is the case, please answer YES.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '29',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '29',
        riskPoints: 0
      }
    ]
  },
  {
    id: '29',
    question:
      'Is the Dutch domestic taxpayer also regarded as a resident for tax purposes in another State?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '30',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '30',
        riskPoints: 0
      }
    ]
  },
  {
    id: '30',
    question:
      'Has the Dutch domestic taxpayer transferred a financial instrument to another party in another State and does Dutch tax law consider the Dutch taxpayer to be the owner of returns of this transferred financial instrument and does the tax law of the other State consider the other party to be the owner of the returns of this transferred financial instrument (hybrid transfer)?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: 'end',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: 'end',
        riskPoints: 0
      }
    ]
  },
  {
    id: '31',
    question:
      'Is the foreign head office of the Dutch permanent establishment based outside the EU?',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '32',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '33',
        riskPoints: 0
      }
    ]
  },
  {
    id: '32',
    question:
      'Does the foreign head office (based outside the EU) provide remunerations or payments that (in principle) are deductible both at the level of the foreign head office and at the level of the Dutch permanent establishment? See Example 4.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '33',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: '33',
        riskPoints: 0
      }
    ]
  },
  {
    id: '33',
    question:
      'Does the Dutch permanent establishment recognise a deemed payment to the foreign head office or a permanent establishment of the foreign taxpayer (the foreign head office)? See Example 5.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '34',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '35',
        riskPoints: 0
      }
    ]
  },
  {
    id: '34',
    question:
      'Is that deemed payment (see previous question) part of the taxable base of the other party (the foreign head office or the permanent establishment of the foreign head office)? See Example 5.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: '35',
        riskPoints: 0
      },
      {
        answer: 'No',
        nextQuestion: '35',
        riskPoints: 1
      }
    ]
  },
  {
    id: '35',
    question:
      'Does a non-EU permanent establishment of the non-resident taxpayer perform a deemed payment to the Dutch permanent establishment, that is deductible from the profit in the non-EU State where the deemed payment is performed? See Example 6.',
    possibleAnswers: [
      {
        answer: 'Yes',
        nextQuestion: 'end',
        riskPoints: 1
      },
      {
        answer: 'No',
        nextQuestion: 'end',
        riskPoints: 0
      }
    ]
  }
];
