import { QuestionService } from './question.service';
import { AnswerService } from './answer.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute
} from '@angular/router';
import { Answer } from '../models/answer.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionGuard implements CanActivate {
  private allQuestionsAnswered$ = this.answerService.allQuestionsAnswered$;
  constructor(
    private answerService: AnswerService,
    private questionService: QuestionService,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let canPass = true;
    const answers: Answer[] = this.answerService.answers$.getValue();

    if (answers.length > 0) {
      const nextQuestionId = this.questionService.getNextQuestionIdFromAnswer(
        answers[answers.length - 1]
      );
      if (state.url.endsWith('questions')) {

        if (state.url.endsWith(nextQuestionId)) {
          canPass = true;
        } else {
          this.router.navigate(['/', 'questions', nextQuestionId]);
        }
      } else if (this.allQuestionsAnswered$ && state.url.endsWith('/')) {
        this.router.navigate(['/', 'report']);
      } else {
        canPass = true;
      }
    } else if (state.url.endsWith('1')) {
      canPass = true;
    } else {
      this.router.navigate(['/', 'questions', '1']);
    }

    return canPass;
  }
}
