import { Question } from './../models/question-list.data';
import { AnswerService } from '../shared/answer.service';
import { QuestionService } from '../shared/question.service';
import { Answer } from '../models/answer.model';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-previous-question',
  templateUrl: './previous-question.component.html',
  styleUrls: ['./previous-question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() answer: Answer;

  constructor(
    private questionService: QuestionService,
    private router: Router,
    private answerService: AnswerService
  ) { }

  ngOnInit() { }

  public setAnswer(possibleAnswer: string, question: Question) {
    const newAnswer: Answer = { question, answer: possibleAnswer };
    if (possibleAnswer !== this.answer.answer) {

      if (this.answerService.answers$.getValue().length > 0) {
        this.answerService.updateAnswerStore(newAnswer);
      } else {
        this.answerService.setAnswerStore(newAnswer);
      }
      const nextQuestionId = this.questionService.getNextQuestionIdFromAnswer(
        newAnswer
      );
      if (nextQuestionId) {
        this.router.navigate(['questions', nextQuestionId]);
      }
    }
  }

  public checkTextForDefinitionsAndExamples(text: string) {
    return this.questionService.checkTextForDefinitionsAndExamples(text);
  }

  public useNgFunctionWithinHtml(input, e: any): void {
    this.questionService.useNgFunctionWithinHtml(input, e);
  }
}
