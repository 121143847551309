export interface Definition { term: string; article?: string; explanation: string; listItems?: string[]; }

export const Terminology: Definition[] = [
    {
        term: 'EU',
        explanation: 'Aside from the Netherlands, there are 25 other Members of the EU: Belgium, Bulgaria, South Cyprus, Denmark, Germany, Estonia, Finland, France, Greece, Hungary, Ireland, Italy, Croatia, Latvia, Lithuania, Luxembourg, Malta, Austria, Poland, Portugal, Romania, Slovenia, Slovakia, Spain and the Czech Republic. The United Kingdom withdrew from the EU on February 1st, 2020. In accordance with the withdrawal agreement between the EU and the United Kingdom, EU law will remain for the most part applicable to the United Kingdom up to and including December 31st, 2020.'
    },
    {
        term: 'Transparent for tax purposes',
        explanation: 'An entity is transparent for tax purposes if the profit of that entity is considered, based on the tax laws of a State, not to be profit of the entity, but rather profit of the participants of that entity.'
    },
    {
        term: 'Associated enterprise',
        article: '(art. 12ac - 2)',
        explanation: `An entity in which the taxpayer holds a participation of 25% or more, or an entity that holds a participation of 25% or more in the taxpayer. If an individual or entity holds a participation of 25% or more in the taxpayer and one or more other entities, all the entities concerned shall also be regarded as associated enterprises of the taxpayer, for the purpose of this article. An entity is also considered to be an associated enterprise of the taxpayer if this entity holds a participation in the taxpayer and this entity acts together with(an)other entity(entities) or person(s) and these entities and person(s) together hold a participation of at least 25% in the taxpayer. These persons and entities who/ which act together are a so called collaborative group (in Dutch 'een samenwerkende groep'). If one or more persons or entities(alone or together) of a so called collaborative group have a participation of at least 25 % in another entity, this latter entity is also considered to be an associated enterprise of the taxpayer. If a collaborative group consisting of the taxpayer, one or more other entities and / or one or more persons has a participation of at least 25 % in another entity, the latter entity is also an associated enterprise. Finally, an associated enterprise also means an entity that is part of the same consolidated group for financial accounting purposes as the taxpayer, an enterprise in which the taxpayer has a significant influence in the management or an enterprise that has a significant influence in the management of the taxpayer.`
    },
    {
        term: 'Structured arrangement',
        article: '(art. 12ac-1f)',
        explanation: 'An arrangement involving a deduction without inclusion or double deduction, and for which the tax benefit is priced into the terms of the arrangement, or an arrangement that has been designed to produce a deduction without inclusion or double deduction, unless the taxpayer or an associated enterprise could not reasonably have been expected to be aware of this outcome and did not share in the value of the resulting tax benefit.'
    },
    {
        term: 'International structure',
        article: '(art. 12aa-2a up to and including c jo. art. 12ac-2)',
        explanation: 'An international structure is present where the taxpayer (directly or indirectly) has foreign subsidiary companies, foreign sister companies or foreign shareholders with an interest of 25% or more. In the absence of foreign subsidiary companies, foreign sister companies or foreign shareholders, there is also an international structure if the taxpayer has a foreign permanent establishment.'
    },
    {
        term: 'Hybrid entity',
        article: '(art. 12ac-1g)',
        explanation: 'An entity that is regarded as a taxable entity under the laws of one State, and whose income or expenditure is treated as income or expenditure of one or more other entities or individuals under the laws of another jurisdiction.'
    },
    {
        term: 'Inclusion',
        article: '(art. 12ac-1a)',
        explanation: 'Inclusion is present if a remuneration, payment or deemed payment is included in a tax on income, even if this could be offset against future losses. If the remuneration, payment or deemed payment is eligible for a tax exemption, there is no inclusion.'
    },
    {
        term: 'Financial instrument',
        article: '(art.12 ac-1e)',
        explanation: 'Any instrument that leads to a revenue on a loan or comparable agreement or on equity, and includes a hybrid transfer.'
    },
    {
        term: 'Hybrid transfer',
        article: '(art. 12aa-1h)',
        explanation: 'An agreement to transfer a financial instrument where the underlying return of the transferred financial instrument is treated, for tax purposes, by the involved States as derived simultaneously by more than one of the parties to that agreement.'
    },
    {
        term: 'Permanent establishment',
        article: '(art. 3-5)',
        explanation: 'A fixed place of business, resident in another State than the State in which the head office is a resident, through which the activities of an enterprise are wholly or in part carried out, with the understanding that a building site or construction or installation project constitutes a permanent establishment only if it lasts more than twelve months. If the Netherlands has concluded a tax treaty with the other State, the definition included in the treaty is applicable. You can use the PE- tracker in the Baker Tilly Tax Mapp(an app developed by Baker Tilly) to assess whether you potentially have a permanent establishment.'
    },
    {
        term: 'Included in the taxable base in the State of the recipient',
        explanation: `Examples of inclusion in the taxable base in the State of the recipient:`,
        listItems: [
            `Inclusion in the taxable base of the recipient itself;`,
            `Inclusion in the taxable base of another entity (resident of the same State) which together with the recipient is part of a group which makes use of a consolidation regime for tax purposes;`
        ]
    }

];
