import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnswerService } from '../answer.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  private respondentName$ = this.answerService.respondentName$;
  public answer$ = this.answerService.answers$;

  public nameForm = new FormGroup({
    name: new FormControl(this.respondentName$.getValue(), [Validators.required])
  });

  constructor(public dialogRef: MatDialogRef<InfoModalComponent>, private answerService: AnswerService) { }

  ngOnInit() { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public continue() {
    this.dialogRef.close();
  }

  public setName() {
    const newName = this.nameForm.controls.name.value;
    if (newName) {
      this.answerService.setNameStore(newName);
      this.continue();
    }
  }

}
