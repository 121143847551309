import { Examples } from './../models/examples.data';
import { Injectable } from '@angular/core';
import { QuestionList } from '../models/question-list.data';
import { Answer } from '../models/answer.model';
import { Terminology } from '../models/terminology.data';
import { MatDialog } from '@angular/material/dialog';
import { TerminologyModalComponent } from './terminology-modal/terminology-modal.component';
import { ExampleModalComponent } from './example-modal/example-modal.component';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  public questionList = QuestionList;
  private terminology = Terminology;
  private examples = Examples;

  constructor(private dialog: MatDialog) { }

  public getNextQuestionIdFromAnswer(answer: Answer): string {
    return answer.question.possibleAnswers.find(a => a.answer === answer.answer)
      .nextQuestion;
  }

  private checkTextForDefinitions(text: string) {
    let newText = text;
    for (const def of this.terminology) {
      const uncapitalizedTerm = def.term.toLowerCase()[0] + def.term.substring(1);
      if (newText.includes(uncapitalizedTerm)) {
        const newWord = `<u>${uncapitalizedTerm}</u>`;
        newText = newText.split(uncapitalizedTerm).join(newWord);
      } else if (newText.includes(def.term)) {
        const newWord = `<u>${def.term}</u>`;
        newText = newText.split(def.term).join(newWord);
      }
    }
    return newText;
  }

  private checkTextForExamples(text: string) {
    let newText = text;
    for (const ex of this.examples) {
      if (newText.includes(ex.title)) {
        const newWord = `<u>${ex.title}</u>`;
        newText = newText.split(ex.title).join(newWord);
      }
    }
    return newText;
  }

  public checkTextForDefinitionsAndExamples(text: string) {
    const textWithDefs = this.checkTextForDefinitions(text);
    const textWithDefsAndExamples = this.checkTextForExamples(textWithDefs);
    return textWithDefsAndExamples;
  }

  private openTerminologyModal(term: string) {
    const definition = this.terminology.find(def => def.term.toLowerCase() === term.toLowerCase());
    this.dialog.open(TerminologyModalComponent, { data: { definition }, panelClass: 'custom-dialog' });
  }

  private openExampleModal(exampleTitle: string) {
    const example = this.examples.find(ex => ex.title === exampleTitle);
    this.dialog.open(ExampleModalComponent, { data: { example }, panelClass: 'custom-dialog' });
  }

  public useNgFunctionWithinHtml(input, e: any): void {
    if ((e.target as HTMLElement).tagName === 'U') {
      if (e.target.textContent.startsWith('Example')) {
        this.openExampleModal(e.target.textContent);
      } else {
        this.openTerminologyModal(e.target.textContent);
      }
    }
  }
}
