import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AnswerService } from '../answer.service';
import Backendless from 'backendless';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Answer } from 'src/app/models/answer.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PdfReportService } from '../pdf-report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {

  private bakerTillyEmail = environment.bakerTillyEmail;
  private answers = this.answerService.answers$.getValue();
  private respondentName = this.answerService.respondentName$.getValue();
  public contactDetailsForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    phone: new FormControl('', [Validators.pattern('^[0-9]*$'), Validators.required])
  });
  public emailGenerationInProgress = false;

  constructor(
    public dialogRef: MatDialogRef<EmailModalComponent>,
    private answerService: AnswerService,
    private snackbar: MatSnackBar,
    private pdfReportService: PdfReportService
  ) { }

  ngOnInit() {
    Backendless.initApp(environment.backendlessAppId, environment.backendlessApiKey);
  }

  public async generateAndSendEmail() {
    this.emailGenerationInProgress = true;
    const fileName = `ATAD2-${this.contactDetailsForm.controls.email.value}-${this.contactDetailsForm.controls.phone.value}-${Date.now()}.pdf`;
    const fileLocation = 'baker-tilly-pdfs';
    const backendlessFileLocation = `${fileLocation}/${fileName}`;

    const pdfFile = await this.pdfReportService.generatePfd(fileName);
    const errorMessage = `Something went wrong processing your report. Please contact ${this.bakerTillyEmail} or try again later`
    try {
      const upload = await Backendless.Files.upload(pdfFile, fileLocation, true);
      console.log('uploaded pdf file - ' + upload);

      await this.sleep(6000);

      const send = await this.sendEmailWithFile(backendlessFileLocation);
      console.log('email sent - ' + send);

      this.snackbar.open(`Thank you for your submission. We will contact you shortly`, 'OK', {
        duration: 6000,
        panelClass: 'styled-snackbar'
      });
      this.dialogRef.close();

    } catch (error) {
      console.error('error ' + error.message);
      this.snackbar.open(errorMessage, 'OK', {
        duration: 6000,
        panelClass: 'styled-snackbar'
      });
    }
  }

  private async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async sendEmailWithFile(backendlessFileLocation: string) {
    const bodyParts = new Backendless.Bodyparts();
    bodyParts.textmessage = '';
    bodyParts.htmlmessage = this.setHtmlAnswerEmail(this.answers, this.respondentName);
    const emailTitle = `ATAD2 report ${this.respondentName} (${this.contactDetailsForm.controls.email.value}, ${this.contactDetailsForm.controls.phone.value})`;
    const attachment = backendlessFileLocation;
    return await Backendless.Messaging.sendEmail(emailTitle, bodyParts, [this.bakerTillyEmail], [attachment]);
  }

  private setHtmlAnswerEmail(answers: Answer[], respondentName: string) {
    const emailContent = [`<p>Dear Sir/Madam,</p><p>Client: ${respondentName} (${this.contactDetailsForm.controls.email.value}, ${this.contactDetailsForm.controls.phone.value}) has requested to be contacted about ATAD2.</p><p>Their relevant questions and answers are shown below:</p>`];
    answers.forEach(answer => {
      const questionListLine = `<p>${answer.question.id}. ${answer.question.question} --- ${answer.answer}`;
      emailContent.push(questionListLine);
    });
    emailContent.push(`<br><p>Attached is their ATAD2 report.</p>`);
    return emailContent.join('');
  }
}
