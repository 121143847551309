import { QuestionService } from '../shared/question.service';
import { Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Question, QuestionList } from '../models/question-list.data';

@Injectable()
export class LatestQuestionResolver implements Resolve<any> {
  public questionList = QuestionList;
  public currentQuestion$: Observable<Question>;

  constructor(private questionService: QuestionService) { }

  resolve(route: ActivatedRouteSnapshot): Question {
    const questionId = route.paramMap.get('questionId');
    return this.questionService.questionList.find((question: Question) => question.id === questionId);
  }
}
