import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InfoModalComponent } from './shared/info-modal/info-modal.component';
import { QuestionListComponent } from './question-list/question-list.component';
import { HeaderComponent } from './shared/header/header.component';
import { LatestQuestionComponent } from './latest-question/latest-question.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReportComponent } from './report/report.component';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { QuestionComponent } from './previous-question/previous-question.component';
import { MatIconModule } from '@angular/material/icon';
import { CardButtonComponent } from './shared/card-button/card-button.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TerminologyModalComponent } from './shared/terminology-modal/terminology-modal.component';
import { ExampleModalComponent } from './shared/example-modal/example-modal.component';
import { EmailModalComponent } from './shared/email-modal/email-modal.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';


@NgModule({
  declarations: [
    AppComponent,
    InfoModalComponent,
    QuestionListComponent,
    HeaderComponent,
    LatestQuestionComponent,
    ReportComponent,
    ConfirmationModalComponent,
    QuestionComponent,
    CardButtonComponent,
    TerminologyModalComponent,
    ExampleModalComponent,
    EmailModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatExpansionModule,
    MatSnackBarModule,
    ReactiveFormsModule,

  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [InfoModalComponent, ConfirmationModalComponent, TerminologyModalComponent, ExampleModalComponent, EmailModalComponent],
  exports: [CardButtonComponent]
})
export class AppModule { }
