import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Answer } from './models/answer.model';
import { AnswerService } from './shared/answer.service';
import { InfoModalComponent } from './shared/info-modal/info-modal.component';
import { Router, NavigationEnd } from '@angular/router';
import { EmailModalComponent } from './shared/email-modal/email-modal.component';

// declare ga as a function to access the JS code in TS
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public answers$: BehaviorSubject<Answer[]> = this.answerService.answers$;
  public allQuestionsAnswered$ = this.answerService.allQuestionsAnswered$;
  public reportPage = false;

  constructor(
    private answerService: AnswerService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.reportPage = e.url.endsWith('report');
        gtag('config', 'G-T26XEFJJPP', 
        {
          'page_path': e.urlAfterRedirects
        }
       );
      }
    });
    console.log('v.0.0.2')
  }

  ngOnInit() {
    this.dialog.open(InfoModalComponent, { panelClass: 'custom-dialog', disableClose: true });
  }

  public clearAnswers() {
    this.dialog.open(ConfirmationModalComponent, { panelClass: 'custom-dialog', disableClose: true });
  }

  public confirmAnswers() {
    this.router.navigate(['.', 'report']);
  }

  public returnToReport() {
    this.router.navigate(['.', 'questions', 'end']);
  }

  public emailReport() {
    this.dialog.open(EmailModalComponent, { panelClass: 'custom-dialog' })
  }
}
