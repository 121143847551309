import { element } from 'protractor';
interface GeneralRemarksSection {
  category: string;
  paragraphs: string[];
}

export const GeneralRemarks: GeneralRemarksSection[] = [
  {
    category: 'Assumptions regarding implementation of EU Directives',
    paragraphs: [`For the purpose of the following questions and the risks described below, the departure point is that all EU Member States have implemented ATAD2 and other EU Directives (e.g. the Parent-Subsidiary Directive) correctly and on time, and satisfy the requirements of these Directives correctly.`]
  },
  {
    category: 'Legal provisions',
    paragraphs: [`The questions and reporting have been drafted based on section 2.2a (Hybrid Mismatches) and art. 15e-9 of the Wet op de vennootschapsbelasting 1969 (Dutch Corporate Income Tax Act or 'CITA'), hereinafter referred to as 'ATAD2'. The provisions cited stem from the CITA unless specifically mentioned otherwise.`]
  },
  {
    category: 'Disclaimer',
    paragraphs: [
      `The content of this web-app (the App) is owned and operated by Baker Tilly (Netherlands) N.V. (hereafter: Baker Tilly).`,
      `Although every effort has been made to ensure that the content of this App is up to date and accurate, errors may occur. The App is provided on an "as is" basis and Baker Tilly  does not guarantee or warrant that the App or the information contained on it is complete or free of errors or viruses or that access to the App will be uninterrupted and without delay. Baker Tilly  accepts no liability for any loss or damage whatsoever arising as a result of any decision or action taken or refrained from as a result of information contained on the App or as a result of your use or misuse of the App.`,
      `The App may include links to third party app's and/or websites that are controlled and maintained by others. Baker Tilly is not responsible for the content or availability of such app's or sites. Any link to a third party's app / website should not be construed as an endorsement by Baker Tilly of that third party or its products or services.`,
      `All copyright, trade marks and other intellectual property rights in the App and its content (including without limitation the App design, text, graphics, and all software and source codes connected with the App) are owned by or licensed to Baker Tilly  or otherwise used by Baker Tilly  as permitted by law. Should a user of the App download material of the App for personal or non-commercial use, the user must retain all copyright, trade mark or other similar notices contained in the original material or any copies of the material. Save as expressly permitted in this paragraph, material on the App may not be downloaded or used for any public or commercial purpose without the written permission of Baker Tilly.`,
      `The outcome of this App cannot be considered as a tax advice or a tax opinion by Baker Tilly. This App only provides a rough indication of the applicability of 'afdeling 2.2a Hybridemismatches Wet op de vennootschapsbelasting 1969' (the Dutch ATAD2 rules) to your company.`,
      `This Legal Statement and all terms and conditions on this App are governed by and shall be construed in accordance with the laws of the Netherlands. In the event of a dispute all matters shall be subject to the exclusive jurisdiction of the Dutch courts.`
    ]
  }
];

interface OverallConclusion { noRisk: string; lowRisk: string; highRisk: string; standardParagraphs: string[]; }
interface OverallRecommendation { notSubject: string; subject: string; standardParagraphs: string[]; }

export const OverallConclusion: OverallConclusion = {
  noRisk: `Based on your answers you might not be liable to CIT in the Netherlands. Therefore Dutch regulations with regard to ATAD2 should not apply to you. Please contact your tax advisor.`,
  lowRisk: `Based on your answers, the risk that ATAD2 will have an effect on your company is low. This is explained in more detail in the section 'Observations' below.`,
  highRisk: `Based on your answers, the risk that ATAD2 will have an effect on your company is high. This is explained in more detail in the section 'Observations' below.`,
  standardParagraphs: [`The report is built up as follows. In the left hand column we summarise the elements that we have recognised with regard to ATAD2. The column named 'Question no' refers to question on which the risk observation is based. In the column named 'Description of the recognised ATAD2 risks for your company', a rough indication of the potential risks is given for each element. In the right hand column, a broad overview of the potential consequences for the administration obligation is presented for each element. The ATAD2 risks and consequences for the administration obligation also depend on your answers. Please be aware that, based on your answers, one remuneration or payment may lead to multiple risks. If further analysis clarifies that one risk is not applicable or can be solved, there still can be a case of denial of deduction or income inclusion because (an)other risk(s) still exists.`]
};

export const OverallRecommendation: OverallRecommendation = {
  notSubject: `Based on your answers you might not be liable to CIT in the Netherlands. Therefore Dutch regulations with regard to ATAD2 are not applicable to you. Please contact your tax advisor.`,
  subject: `Based on art. 12ag, you are subject to an administration obligation regarding the application of ATAD2 (hereinafter: 'ATAD2 administration'). In that context, we recommend you to include a diagram of the structure in your ATAD2 administration.`,
  standardParagraphs: [`A diagram of the structure is understood to be a diagram (drawing) of the legal (corporate) structure of which the taxpayer is a part, which 1) includes the legal or economic interest noted as a percentage, 2) notes whether the entity (entities) involved is (are) hybrid or transparent, and 3) notes the State (jurisdiction) where the entity is a resident. The diagram of the structure preferably includes an overview of any loans that have been granted and other financial instruments.`, `We will give, if applicable, a more detailed recommendation in the section 'Observations' below.`]
};

export interface ObservationAdvice {
  question: string;
  answer: string;
  risk: string;
  obligation: string;
}

export interface Observation {
  element: string;
  id: number;
  advice: ObservationAdvice[];
}

export const Observations: Observation[] = [
  {
    element: `Deduction in the Netherlands without inclusion or double Deduction because the Dutch tax paying entity is considered transparent. Art. 12aa-1e and art. 12aa-1g`,
    id: 0,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '4',
        answer: 'No',
        risk: `"&NaamA&" is considered fiscally non-transparent by the State in which the shareholder/participant is a resident. Therefore, with regard to remunerations and payments to the direct shareholder/participant, there is a low risk for ATAD2. Additionally, there is a low risk of double deduction with regard to the direct shareholder/participant.`,
        obligation: `-`,
      },
      {
        question: '4',
        answer: 'Yes',
        risk: `"&NaamA&" is considered fiscally transparent by the State in which the shareholder/participant is resident. This creates an ATAD2 risk that expenses (such as interest paid to the bank, or for example amortisation charges) are deductible both in the Netherlands and in the State of the shareholder/participant. In such a case, the Netherlands will in principle deny the deduction. In the case of remunerations or payments to the shareholder/participant, there is a risk that these expenses are not deductible in the Netherlands as a result of ATAD2, as the corresponding income is not taxed in the State of the recipient (this transaction is non-existent for tax purposes in the State of the recipient). Both risks described above may potentially be mitigated if there is dual inclusion of the income (art. 12aa-3); in such cases, the expenses may be (partially) deductible. Double inclusion of income means that the income is taken into account in both the Netherlands and the State in which the shareholder/participant is subject to taxation.`,
        obligation: `Insofar as there is double inclusion of income, the deduction in the Netherlands remains possible (art. 12aa-3). If you hold the view that there is dual inclusion of income, the ATAD2 administration must include information supporting this position (e.g. a foreign corporate income tax return or an advice or legal opinion from a foreign tax advisor).`,
      },
    ]
  },
  {
    element: `Payments to (foreign) associated hybrid entities. Art. 12aa-1b`,
    id: 1,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '5',
        answer: 'No',
        risk: `As "&NaamA&" does not provide remunerations or payments to the (foreign) associated enterprises, the ATAD2 risk with regard to this element is low.`,
        obligation: `-`,
      },
      {
        question: '6',
        answer: 'No',
        risk: `"&NaamA&" provides remunerations or payments to a (foreign) associated enterprise (the recipient). You have stated that the recipient(s) is (are) not a hybrid entity (entities). In that case, there is no discrepancy between the fiscal qualification of the recipient by the State in which the recipient is resident and the fiscal qualification of the recipient by the State in which the participant of the recipient is resident. The risk that ATAD2 is applicable stemming from art. 12aa-1b is low.`,
        obligation: `-`,
      },
      {
        question: '6',
        answer: 'Yes',
        risk: `"&NaamA&" runs the risk that the expense which is deductible in the Netherlands is not included in taxation elsewhere, because the State, in which the recipient is a resident, considers the recipient to be fiscally transparent (not independently subject to taxation), and the State, in which the participant of the recipient is resident, considers the recipient as independently subject to taxation. If the payment is not included in taxation in the other State, then the expense is not deductible in the Netherlands based on art. 12aa-1b.`,
        obligation: `If you hold the view that the payment or remuneration is included in taxation elsewhere, the ATAD2 administration must include information supporting this position (e.g. a foreign corporate income tax return or an advice or legal opinion from a foreign tax advisor).`,
      },
    ]
  },
  {
    element: `Financial instruments. Art. 12aa-1a`,
    id: 2,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '5',
        answer: 'No',
        risk: `As "&NaamA&" does not provide remunerations or payments to the (foreign) associated enterprises, the ATAD2 risk with regard to this element is low.`,
        obligation: `-`,
      },
      {
        question: '7',
        answer: 'No',
        risk: `ATAD2 regarding financial instruments as mentioned in art. 12aa-1a is probably not applicable as "&NaamA&" does not provide remunerations or payments to foreign associated enterprises based outside the EU. Please note: If the remuneration or payment, stemming from a financial instrument, provided to an associated enterprise resident in the EU, leads to a difference in qualification of the financial instrument, the only example known to us is the situation where an interest payment deductible in the Netherlands is qualified as exempt dividend in the other EU Member State. Based on the Parent-Subsidiary Directive the recipient State is obliged to tax the revenue (cf. art. 12aa-5). We therefore consider that it is plausible that no hybrid mismatch, stemming from a financial instrument, occurs in cases of payments to associated enterprises in the EU. For the purpose of the questions and descriptions of risks, it has been assumed that all EU Member States have implemented the EU Directives on time and correctly.`,
        obligation: `-`,
      },
      {
        question: '8',
        answer: 'Yes',
        risk: `There are remunerations or payments to an associated enterprise. The corresponding revenue is included in the taxation of the recipient within 12 months of the moment of deduction in the Netherlands. The risk that ATAD2 is applicable with regard to art. 12aa-1a, is low.`,
        obligation: `We recommend you to include documentation in your ATAD2 administration which shows that the corresponding revenue is included in taxation within the set time limits. This may be done using corporate income tax returns or assessments from the State in which the recipient is resident. You could also ask a foreign tax advisor to confirm that the corresponding revenue is included in taxation within the set time limits.`,
      },
      {
        question: '8',
        answer: 'No',
        risk: `"&NaamA&" provides remunerations or payments to one or more non-EU associated enterprises. The corresponding revenue is not included in the taxable base of the recipient within 12 months of the moment of deduction in the Netherlands. This may indicate a difference in qualification with regard to the remuneration or payment of a financial instrument (e.g. a payment is considered to be deductible interest in the Netherlands, but is treated as exempt dividend at the level of the recipient). You run the risk that expenses, which are in principle deductible, may not be deducted in the Netherlands due to the fact that the corresponding revenue is not included in the taxable base (on time) at the level of the associated enterprise (art. 12aa-1a).`,
        obligation: `If you wish to deduct the remuneration or payment from the taxable profits, even though the corresponding revenue is not included in the taxable base of the recipient within 12 months from the moment of deduction in the Netherlands, the administration must contain information that shows art. 12aa-1a is not applicable. This analysis should include at least the factual information about the financial instrument (e.g. a description of the loan agreement) and the fiscal qualification of the financial instrument by both States. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Imported hybrid mismatches. Art. 12ad-1`,
    id: 3,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '5',
        answer: 'No',
        risk: `As "&NaamA&" does not provide remunerations or payments to the (foreign) associated enterprises, the ATAD2 risk with regard to this element is low.`,
        obligation: `-`,
      },
      {
        question: '9',
        answer: 'No',
        risk: `"&NaamA&" has not provided remunerations or payments which are subsequently (directly or indirectly) passed-on. The risk of an imported mismatch based on art. 12ad-1 is therefore low.`,
        obligation: `We recommend you to have the recipient confirm that there has been no related passing-on of the remunerations or payments. We recommend you to include this confirmation in your ATAD2 administration.`,
      },
      {
        question: '10',
        answer: 'No',
        risk: `There are remunerations or payments to an associated enterprise, that passes on these remunerations or payments. None of the (subsequent) payments have been passed on to one or more entities resident outside the EU. Our starting point is that all EU Member States have implemented ATAD2 correctly and on time, and that they comply with the Directive. This means that the mismatch is resolved where it arises. This should mean that the risk of an imported mismatch in the Netherlands is low.`,
        obligation: `We recommend you to have the group head confirm that there is no related passing-on of a payment to an associated enterprise outside the EU. We recommend you to include this confirmation in your ATAD2 administration.`
      },
      {
        question: '11',
        answer: 'No',
        risk: `"&NaamA&" has provided remunerations or payments which are (ultimately) directly or indirectly passed on to a non-EU entity. As you have indicated, the corresponding payments, that have been passed-on, have always been included in taxation at the level of the recipient(s). As such, the risk that ATAD2 is applicable with regard to an imported mismatch (art. 12ad-1) is low.`,
        obligation: `We recommend you to have the group head confirm that all revenue stemming from (subsequently) passed-on payments is included in taxation. We recommend you to include this confirmation in your ATAD2 administration.`,
      },
      {
        question: '11',
        answer: 'Yes',
        risk: `"&NaamA&" runs a risk of non-deductible expenses as there may be an imported mismatch based on art. 12ad-1. This entails that a taxpayer performs a payment to an associated enterprise, which is included in taxation at the level of the associated enterprise. This entity passes the revenue on to another associated enterprise, which may, perhaps, in turn pass the revenue on to another associated enterprise. The first passed-on payment, or one of the subsequent payments, is performed to a non-EU entity. A hybrid mismatch occurs in one of the payments (regardless of whether this is due to a financial instrument). The expense is deductible, but the revenue is not (fully) included in taxation. The deduction in the Netherlands is denied insofar as the revenue is not included in taxation. It is noted that causality is required (to be determined based on facts and circumstances) between the payments that have been passed-on.`,
        obligation: `We recommend you to analyse further, perhaps in cooperation with your Dutch and a foreign tax advisor, whether there is a causally linked passing-on of a payment. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Differences in allocation of corresponding revenue at the level of foreign head office and its permanent establishment. Art.12aa-1c`,
    id: 4,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '5',
        answer: 'No',
        risk: `As "&NaamA&" does not provide remunerations or payments to the (foreign) associated enterprises, the ATAD2 risk with regard to this element is low.`,
        obligation: `-`,
      },
      {
        question: '12',
        answer: 'No',
        risk: `As the recipient does not have a permanent establishment, there is no ATAD2 risk with regard to art. 12aa-1c/1d.`,
        obligation: `We recommend you to have the recipient confirm that the recipient does not have a permanent establishment. We recommend you to include the confirmation in your ATAD2 administration.`
      },
      {
        question: '13',
        answer: 'No',
        risk: `Based on the tax laws of the State of the recipient, the revenue is not allocated to the permanent establishment of the recipient. The ATAD2 risk with regard to art.12aa-1c is therefore low.`,
        obligation: `We recommend you to include documentation in your ATAD2 administration, which shows that the corresponding revenue is included in the taxation of the recipient. This may be done using corporate income tax returns or assessments from the State in which the recipient is resident. You could also ask a foreign tax advisor to confirm this inclusion, for example in an advice or a legal opinion.`,
      },
      {
        question: '13',
        answer: 'Yes',
        risk: `"&NaamA&" runs a risk that expenses which are in principle deductible, may (partially) not be deducted in the Netherlands as a consequence of the application of ATAD2. Deduction without (full) inclusion in taxation may potentially occur, as the corresponding revenue is not (fully) included in taxation at the level of the foreign entity (head office) nor is (the remaining part of) the revenue included in taxation at the level of the permanent establishment of the head office. This is caused by differences between tax laws of the State of residence of the head office and those of the State of residence of the permanent establishment, regarding the allocation of revenue. This concerns art. 12aa-1c.`,
        obligation: `We recommend you to investigate further whether the remunerations or payments were not (fully) included in taxation due to a difference between the tax laws of the State of the recipient (head office) and those of the State of the permanent establishment.  This may for example follow from the corporate income tax returns or assessments of the involved States, or from an advice or legal opinion from a foreign tax advisor. We recommend you to include this documentation in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `No inclusion in taxation because the State of residence of the permanent establishment of a foreign head office does not recognise the permanent establishment. Art.12aa-1d`,
    id: 5,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '5',
        answer: 'No',
        risk: `As "&NaamA&" does not provide remunerations or payments to the (foreign) associated enterprises, the ATAD2 risk with regard to this element is low.`,
        obligation: `-`,
      },
      {
        question: '12',
        answer: 'No',
        risk: `As the recipient does not have a permanent establishment, there is no ATAD2 risk with regard to art. 12aa-1c/1d.`,
        obligation: `-`,
      },
      {
        question: '13',
        answer: 'No',
        risk: `As the revenue is not allocated to the permanent establishment of the receiving entity, the ATAD2 risk regarding art. 12aa-1d is low.`,
        obligation: `If the documentation of the administration requirement regarding art. 12aa-1c sufficiently shows that the corresponding revenue is included in a tax on profit at the level of the receiving entity, no additional administration requirement arises regarding art. 12aa-1d.`,
      },
      {
        question: '14',
        answer: 'Yes',
        risk: `The permanent establishment(s) of the recipient is (are) recognised in the State where it is (they are) located. Therefore, risk of application of ATAD2 with regard to art. 12aa-1d, is low.`,
        obligation: `We recommend you to include documents in your ATAD2 administration which show that the permanent establishment of the recipient is fiscally recognised in the State where it is located. This may for example be done based on the corporate income tax returns or assessment from the State where the permanent establishment is located. You may also ask a foreign tax advisor to confirm this.`,
      },
      {
        question: '14',
        answer: 'No',
        risk: `"&NaamA&" runs a risk that expenses which are in principle deductible, may not be deducted. Deduction without inclusion in taxation may potentially occur. The latter is the case if the corresponding revenue is not included in taxation at the level of the foreign entity (head office) nor at the level of the permanent establishment of that entity, because the State where the permanent establishment is located does not recognise the permanent establishment. In such a case, deduction in the Netherlands is denied based on art. 12aa-1d.`,
        obligation: `We recommend you to investigate further to what extent the corresponding revenue is not included in taxation due to the fact that the State where the permanent establishment is located does not recognise the permanent establishment. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Difference in allocation in cases of multiple permanent establishments outside the EU. Art. 12ab-1`,
    id: 6,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '15',
        answer: 'No',
        risk: `"&NaamA&" does not have a foreign permanent establishment. Therefore, the ATAD2 risk with regard to this element of ATAD2 is low.`,
        obligation: `-`,
      },
      {
        question: '16',
        answer: 'No',
        risk: `"&NaamA&" does not have a foreign permanent establishment outside the EU. Therefore, the risk of deemed payments between permanent establishments outside the EU does not arise, and the risk that art. 12ab-1 is applicable with regard to this element, is low.`,
        obligation: `-`,
      },
      {
        question: '17',
        answer: 'No',
        risk: `"&NaamA&" has one permanent establishment outside the EU. Therefore, the risk of deemed payments between permanent establishments outside the EU does not arise, and the risk that art. 12ab-1 is applicable with regard to this element, is low.`,
        obligation: `-`,
      },
      {
        question: '17',
        answer: 'Yes',
        risk: `"&NaamA&" has multiple permanent establishments outside the EU. This, amongst other things, may give rise to a potential risk based on art. 12ab-1. There may be deemed payments between these permanent establishments outside the EU, whereby the deemed payment may be deducted at the level of one permanent establishment without the corresponding revenue being included in taxation at the level of the other permanent establishment.`,
        obligation: `We recommend you to investigate whether a deemed payment takes place between two permanent establishments, whereby the deemed payment may be deducted at the level of one permanent establishment without the corresponding revenue being included in taxation at the level of the other permanent establishment. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Object exemption not granted because the permanent establishment of the taxpayer is not recognised by the State of residence of the permanent establishment. Art. 15e-9`,
    id: 7,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '15',
        answer: 'No',
        risk: `"&NaamA&" does not have a foreign permanent establishment. Therefore, the ATAD2 risk with regard to this element of ATAD2 is low.`,
        obligation: `-`,
      },
      {
        question: '18a',
        answer: 'Yes',
        risk: `As the permanent establishment is recognised by the State where it is located, the ATAD2 risk with regard to art. 15e-9 is low.`,
        obligation: `We recommend you to include documents in your ATAD2 administration which show that the permanent establishment is recognised in the State where it is resident. This could for example be corporate income tax returns or assessments.`,
      },
      {
        question: '18a',
        answer: 'No',
        risk: `"&NaamA&" is not granted the object exemption for the profits of the unrecognised permanent establishment based on art. 15e-9. As the State of residence of the permanent establishment does not recognise the permanent establishment, the profits realised there are not included in taxation in the State of the permanent establishment. By not granting the object exemption, the profits remain included in taxation.`,
        obligation: `You have indicated that there is a permanent establishment which is not recognised abroad. The object exemption is therefore not granted. No additional documentation requirement arises regarding art. 15e-9.`,
      },
      {
        question: '18b',
        answer: 'Yes',
        risk: `As the permanent establishment is recognised by the State where it is located, the ATAD2 risk with regard to art. 15e-9 is low.`,
        obligation: `We recommend you to include documents in your ATAD2 administration which show that the permanent establishment is recognised in the State where it is resident. This could for example be corporate income tax returns or assessments.`,
      },
      {
        question: '18b',
        answer: 'No',
        risk: `"&NaamA&" is not granted the object exemption for the profits of the unrecognised permanent establishment based on art. 15e-9. As the State of residence of the permanent establishment does not recognise the permanent establishment, the profits realised there are not included in taxation in the State of the permanent establishment. By not granting the object exemption, the profits remain included in taxation.`,
        obligation: `You have indicated that there is a permanent establishment which is not recognised abroad. The object exemption is therefore not granted. No additional documentation requirement arises regarding art. 15e-9.`,
      },
    ]
  },
  {
    element: `Deduction at the level of both the domestic taxpayer and the permanent establishment. Art.12aa-1g`,
    id: 8,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '15',
        answer: 'No',
        risk: `"&NaamA&" does not have a foreign permanent establishment. Therefore, the ATAD2 risk with regard to this element of ATAD2 is low.`,
        obligation: `-`,
      },
      {
        question: '19a',
        answer: 'No',
        risk: `"&NaamA&" runs a low risk that ATAD2 is applicable for art. 12aa-1g, as you have indicated that there has been no double deduction with regard to art. 12aa-1g.`,
        obligation: `We recommend you to include information in your ATAD2 administration which shows that no double deduction occurs (i.e. deduction at both the level of the Dutch head office and the foreign permanent establishment). We recommend you to include this information in your ATAD2 administration.`,
      },
      {
        question: '19a',
        answer: 'Yes',
        risk: `"&NaamA&" runs the risk that the deduction is denied in the Netherlands based on art. 12aa-1g. This is due to the fact that the expenses are in principle deductible at the level of both the Dutch head office and the foreign permanent establishment. In such a situation, the Netherlands will deny deduction at the level of the domestic taxpayer (head office). The risk is potentially mitigated if there is dual inclusion income (art. 12aa-3). In cases of dual inclusion income, the expenses may potentially be (partially) deductible. Dual inclusion income means that income is taxed both in the Netherlands and in the State where the permanent establishment is included in taxation.`,
        obligation: `Based on the corporate income tax return of the Dutch entity and the corporate income tax returns/assessments of the foreign permanent establishment, you may (potentially) be able to determine that the revenue is included in taxation in both states. A fiscal advice or legal opinion showing that there is dual inclusion income may also be possible. We recommend you to include this documentation in your ATAD2 administration.`,
      },
      {
        question: '19b',
        answer: 'No',
        risk: `"&NaamA&" runs a low risk that ATAD2 is applicable for art. 12aa-1g, as you have indicated that there has been no double deduction with regard to art. 12aa-1g.`,
        obligation: `We recommend you to include information in your ATAD2 administration which shows that no double deduction occurs (i.e. deduction at both the level of the Dutch head office and the foreign permanent establishment). We recommend you to include this information in your ATAD2 administration.`,
      },
      {
        question: '19b',
        answer: 'Yes',
        risk: `"&NaamA&" runs the risk that the deduction is denied in the Netherlands based on art. 12aa-1g. This is due to the fact that the expenses are in principle deductible at the level of both the Dutch head office and the foreign permanent establishment. In such a situation, the Netherlands will deny deduction at the level of the domestic taxpayer (head office). The risk is potentially mitigated if there is dual inclusion income (art. 12aa-3). In cases of dual inclusion income, the expenses may potentially be (partially) deductible. Dual inclusion income means that income is taxed both in the Netherlands and in the State where the permanent establishment is included in taxation.`,
        obligation: `Based on the corporate income tax return of the Dutch entity and the corporate income tax returns/assessments of the foreign permanent establishment, you may (potentially) be able to determine that the revenue is included in taxation in both states. A fiscal advice or legal opinion showing that there is dual inclusion income may also be possible. We recommend you to include this documentation in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `The permanent establishment does not recognise the deemed payment by the domestic taxpayer. Art.12aa-1f`,
    id: 9,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '15',
        answer: 'No',
        risk: `"&NaamA&" does not have a foreign permanent establishment. Therefore, the ATAD2 risk with regard to this element of ATAD2 is low.`,
        obligation: `-`,
      },
      {
        question: '20a',
        answer: 'No',
        risk: `As there is no deemed (notional) payment, the risk that ATAD2 is applicable for art. 12aa-1f is low.`,
        obligation: `-`,
      },
      {
        question: '21a',
        answer: 'No',
        risk: `As the deemed (notional) payment is included in taxation at the level of the permanent establishment, the risk that ATAD2 is applicable for art. 12aa-1f is low.`,
        obligation: `We recommend you to include documentation in your ATAD2 administration which shows that the corresponding revenue is included in the taxation of the permanent establishment. This could for example be the corporate income tax returns or assessments of the involved States, or a legal opinion by a foreign tax advisor.`,
      },
      {
        question: '21a',
        answer: 'Yes',
        risk: `"&NaamA&" runs the risk that the deduction is denied in the Netherlands because a deemed (notional) payment (by the head office to the permanent establishment) is not recognised by the State of the permanent establishment and therefore not included in taxation (art. 12aa-1f). The risk is potentially mitigated if there is dual inclusion income (art. 12aa-3). In cases of dual inclusion income, the expenses may be (partially) deductible. Dual inclusion income means that income is included in taxation both in the Netherlands and in the State where the permanent establishment is based.`,
        obligation: `We recommend you to investigate whether there is dual inclusion income, which would lead to continued deductibility in the Netherlands (art. 12aa-3). You may (potentially) be able to determine that income is included in taxation in both States, based on the corporate income tax return of the Dutch entity and corporate income tax returns or assessments of the permanent establishment. You can also have your tax advisor, in cooperation with a foreign tax advisor, investigate whether there is dual inclusion income,by means of for example an advice or legal opinion. We recommend you to include this documentation in your ATAD2 administration.`,
      },
      {
        question: '20b',
        answer: 'No',
        risk: `As there is no deemed (notional) payment, the risk that ATAD2 is applicable for art. 12aa-1f is low.`,
        obligation: `-`,
      },
      {
        question: '21b',
        answer: 'No',
        risk: `As the deemed (notional) payment is included in taxation at the level of the permanent establishment, the risk that ATAD2 is applicable for art. 12aa-1f is low.`,
        obligation: `We recommend you to include documentation in your ATAD2 administration which shows that the corresponding revenue is included in the taxation of the permanent establishment. This could for example be the corporate income tax returns or assessments of the involved States, or a legal opinion by a foreign tax advisor.`,
      },
      {
        question: '21b',
        answer: 'Yes',
        risk: `"&NaamA&" runs the risk that the deduction is denied in the Netherlands because a deemed (notional) payment (by the head office to the permanent establishment) is not recognised by the State of the permanent establishment and therefore not included in taxation (art. 12aa-1f). The risk is potentially mitigated if there is dual inclusion income (art. 12aa-3). In cases of dual inclusion income, the expenses may be (partially) deductible. Dual inclusion income means that income is included in taxation both in the Netherlands and in the State where the permanent establishment is based.`,
        obligation: `We recommend you to investigate whether there is dual inclusion income, which would lead to continued deductibility in the Netherlands (art. 12aa-3). You may (potentially) be able to determine that income is included in taxation in both States, based on the corporate income tax return of the Dutch entity and corporate income tax returns or assessments of the permanent establishment. You can also have your tax advisor, in cooperation with a foreign tax advisor, investigate whether there is dual inclusion income,by means of for example an advice or legal opinion. We recommend you to include this documentation in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Remunerations or payments provided by a non-EU associated entity to the Dutch taxpayer which is partially allocated to a PE of the Dutch taxpayer. Deduction in a non-EU country without inclusion in the Netherlands. Art.12ab-1`,
    id: 10,
    advice: [

      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '15',
        answer: 'No',
        risk: `"&NaamA&" does not have a foreign permanent establishment. Therefore, the ATAD2 risk with regard to this element of ATAD2 is low.`,
        obligation: `-`,
      },
      {
        question: '22a',
        answer: 'No',
        risk: `You have indicated that there are no remunerations or payments by an associated enterprise based outside the EU (which are deductible in that non-EU State) to "&NaamA&". Therefore, the ATAD2 risk with regard to art. 12ab-1 is low.`,
        obligation: `-`,
      },
      {
        question: '23a',
        answer: 'No',
        risk: `You have indicated that the remunerations or payments by an associated enterprise based outside the EU (which are in principle deductible in that non-EU State) are not (partially) allocated to a permanent establishment of the domestic taxpayer. Therefore, there the ATAD2 risk with regard to art. 12ab-1 is low.`,
        obligation: `We recommend you to document that the corresponding revenue is included in Dutch taxation. This may for example be done with a corporate income tax return. You could also ask your tax advisor for confirmation of inclusion. We recommend you to include this documentation in your ATAD2 administration.`,
      },
      {
        question: '23a',
        answer: 'Yes',
        risk: `"&NaamA&" receives remunerations or payments from associated enterprises based outside the EU, which are allocated to a permanent establishment of the Dutch taxpayer. As a result, the remunerations or payments are not included in Dutch taxation (object exemption). You may be required to include the payments or remunerations as income in the Dutch corporate income tax return. This is the case if the remunerations or payments are not included in taxation in the State of the permanent establishment.`,
        obligation: `We recommend you to investigate whether remunerations or payments, which are received from an associated enterprise based outside the EU and which are allocated to the permanent establishment of the taxpayer, are included in taxation in the State of the permanent establishment. We recommend you to include this analysis in your ATAD2 administration.`,
      },
      {
        question: '22b',
        answer: 'No',
        risk: `You have indicated that there are no remunerations or payments by an associated enterprise based outside the EU (which are deductible in that non-EU State) to "&NaamA&". Therefore, the ATAD2 risk with regard to art. 12ab-1 is low.`,
        obligation: `-`,
      },
      {
        question: '23b',
        answer: 'No',
        risk: `You have indicated that the remunerations or payments by an associated enterprise based outside the EU (which are in principle deductible in that non-EU State) are not (partially) allocated to a permanent establishment of the domestic taxpayer. Therefore, there the ATAD2 risk with regard to art. 12ab-1 is low.`,
        obligation: `We recommend you to document that the corresponding revenue is included in Dutch taxation. This may for example be done with a corporate income tax return. You could also ask your tax advisor for confirmation of inclusion. We recommend you to include this documentation in your ATAD2 administration.`,
      },
      {
        question: '23b',
        answer: 'Yes',
        risk: `"&NaamA&" receives remunerations or payments from associated enterprises based outside the EU, which are allocated to a permanent establishment of the Dutch taxpayer. As a result, the remunerations or payments are not included in Dutch taxation (object exemption). You may be required to include the payments or remunerations as income in the Dutch corporate income tax return. This is the case if the remunerations or payments are not included in taxation in the State of the permanent establishment.`,
        obligation: `We recommend you to investigate whether remunerations or payments, which are received from an associated enterprise based outside the EU and which are allocated to the permanent establishment of the taxpayer, are included in taxation in the State of the permanent establishment. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Deemed payment by a permanent establishment outside the EU to a taxpayer (head office) is not recognised based on Dutch tax law. Art. 12ab-1`,
    id: 11,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '15',
        answer: 'No',
        risk: `"&NaamA&" does not have a foreign permanent establishment. Therefore, the ATAD2 risk with regard to this element of ATAD2 is low.`,
        obligation: `-`,
      },
      {
        question: '16',
        answer: 'No',
        risk: `"&NaamA&" does not have a foreign permanent establishment outside the EU. Therefore, deemed payments of a permanent establishment outside the EU to the Dutch head office do not take place. The risk that art. 12ab-1 is applicable with regard to this element, is low.`,
        obligation: `-`,
      },
      {
        question: '24',
        answer: 'No',
        risk: `You have indicated that there are no deemed (notional) payments. The risk of art. 12ab-1 being applicable is therefore low (with regard to the element of deemed payment by the permanent establishment to the Dutch head office). In cases of a deemed (notional) payment by a permanent establishment resident in the EU, we do not see an ATAD2 risk for this element, as we assume that the other EU member State has implemented ATAD2 correctly and on time, and that any required correction (denial of deduction) is resolved there.`,
        obligation: `We recommend you to include documentation in your ATAD2 administration that shows there is no deemed payment by the permanent establishment to the head office. This may for example be a confirmation on this matter by the foreign tax advisor responsible for filing the foreign corporate income tax return.`,
      },
      {
        question: '25',
        answer: 'Yes',
        risk: `You have indicated that the deemed (notional) payment has been included in Dutch taxation. The risk of art. 12ab-1 being applicable is therefore low (with regard to the element of deemed payment by the permanent establishment to the Dutch head office). In cases of a deemed (notional) payment by a permanent establishment resident in the EU, we do not see an ATAD2 risk for this element, as we assume that the other EU member State has implemented ATAD2 correctly and on time, and that any required correction (denial of deduction) is resolved there`,
        obligation: `We recommend you to document that the corresponding revenue is included in Dutch taxation. This may be done with the corporate income tax return. You may also ask your tax advisor for confirmation of inclusion. We recommend you to include this documentation in your ATAD2 administration.`,
      },
      {
        question: '25',
        answer: 'No',
        risk: `"&NaamA&" runs the ATAD2 risk that the corresponding revenue which is not recognised based on Dutch tax law, is nevertheless included in taxation based on art. 12ab-1.`,
        obligation: `We recommend you to investigate whether a deemed (notional) payment by a permanent establishment outside the EU to the taxpayer (head office) is not recognised based on Dutch tax law. In such a case, the taxpayer must include that payment in its taxable profits based on art. 12ab-1. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Foreign hybrid subsidiary enterprise of taxpayer receives remunerations or payments from an associated enterprise that are untaxed both at the level of the subsidiary and the taxpayer. Art. 12ab-1`,
    id: 12,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '3',
        answer: 'No',
        risk: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
        obligation: `ATAD2 is not applicable, "&NaamA&" is not part of an international structure.`,
      },
      {
        question: '26',
        answer: 'No',
        risk: `"&NaamA&" is not a participant in a foreign hybrid subsidiary enterprise. The risk with regard to this element of art. 12ab-1 is low.`,
        obligation: `-`,
      },
      {
        question: '27',
        answer: 'No',
        risk: `Your foreign hybrid subsidiary enterprise does not receive remunerations or payments from another associated enterprise. The risk with regard to this element of art. 12ab-1 is therefore low.`,
        obligation: `We recommend you to request a confirmation from your foreign hybrid subsidiary enterprise that this entity has not received remunerations or payments from entities associated with your entity, and to include this in your ATAD2 administration.`,
      },
      {
        question: '27',
        answer: 'Yes',
        risk: `Your foreign hybrid subsidiary enterprise receives payments from another associated enterprise. Consequently, there is a risk that, due to the hybrid nature of your subsidiary enterprise, the corresponding revenue is not included in taxation at the level of the foreign hybrid subsidiary entity (which is deemed transparent there), nor in the Netherlands, as the Netherlands considers your foreign subsidiary as independently subject to taxation. ATAD2 (art. 12ab-1) dictates that the revenue must then be taxed in the Netherlands.`,
        obligation: `We recommend you to have the taxability of the remunerations or payments analysed by a tax advisor and to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Structured arrangement. Art.12ad-1`,
    id: 13,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '28',
        answer: 'No',
        risk: `"&NaamA&" runs a low risk regarding art. 12ad-1 for the element 'structured arrangement', as you have indicated that there is no structured arrangement present.`,
        obligation: `-`,
      },
      {
        question: '28',
        answer: 'Yes',
        risk: `The remunerations and payments arising from a structured arrangement may potentially not be deductible.`,
        obligation: `We recommend you to have the structured arrangement analysed by a tax advisor, and to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Double fiscal residency. Art.12ae-1`,
    id: 14,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '29',
        answer: 'No',
        risk: `As you have indicated, "&NaamA&" is regarded a resident for tax purposes solely in the Netherlands. Therefore, the ATAD2 risk with regard to art.12ae is low.`,
        obligation: `-`,
      },
      {
        question: '29',
        answer: 'Yes',
        risk: `"&NaamA&" is regarded as resident for tax purposes in more than one State. This may potentially give rise to an ATAD2 risk stemming from art. 12ae. Remunerations, payments, expenses or losses which are also deductible in another State of residence, are not deductible in the Netherlands, unless the deduction is offset against dual inclusion income.`,
        obligation: `We recommend you to investigate whether remunerations, payments, expenses or losses are also deducted in another State of residence, and, if so, whether the deduction is offset against dual inclusion income. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Hybrid transfer of financial instruments. Art. 12aa-1 jo art. 12ac-1h`,
    id: 15,
    advice: [
      {
        question: '1 & 2',
        answer: '',
        risk: `Not applicable`,
        obligation: `Not applicable`,
      },
      {
        question: '30',
        answer: 'No',
        risk: `No hybrid transfer has taken place, so the risk with regard to this element of ATAD2 is low.`,
        obligation: `-`,
      },
      {
        question: '30',
        answer: 'Yes',
        risk: `There may be a transfer of a financial instrument whereby the return of the transferred financial instrument is taken into account for tax purposes in both the State of the transferor and the State of the transferee. If the hybrid transfer leads to deduction without (full) inclusion in taxation within a reasonable term, deduction may be denied to that extent.`,
        obligation: `We recommend you to have the hybrid transfer and the transactions of which the hybrid transfer are a part, analysed by a tax advisor with regard to the consequences for the deductibility of expenses. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Double deduction (art.12aa-1g)`,
    id: 16,
    advice: [
      {
        question: '31',
        answer: 'No',
        risk: `As the head office of the Dutch permanent establishment is located within the EU, the risk with regard to this element of ATAD2 is in our view low. Our starting point is that all EU Member States have implemented ATAD2 correctly and on time. This means that if there is an occurrence of double deduction (at both the level of the head office and the Dutch permanent establishment), the EU State where the head office is resident will deny the deduction.`,
        obligation: '-',
      },
      {
        question: '32',
        answer: 'No',
        risk: `As no double deduction takes place, the risk for ATAD2 with regard to art. 12aa-1g is low.`,
        obligation: `We recommend you to have the head office confirm that there is no double deduction. We recommend you to include this confirmation in your ATAD2 administration.`,
      },
      {
        question: '32',
        answer: 'Yes',
        risk: `Part of the expenses is deductible both in the Netherlands and in the State of the head office. The Dutch permanent establishment therefore runs the risk that deduction in the Netherlands may be denied to that extent, if the head office is located outside the EU and no regulations comparable to ATAD2 have been implemented there (art. 12aa-1g). The risk may be mitigated in cases of dual inclusion income (art. 12aa-3), in which case the expenses may still be (partially) deductible. Dual inclusion income means that income is included in taxation both at the level of the Dutch permanent establishment and the foreign head office.`,
        obligation: `Double deduction of (part of) the expenses takes place based on art. 12aa-1g. We recommend you to document the facts and circumstances that lead you to this conclusion. We recommend you to check whether there may be dual inclusion income, which would lead to the deduction in the Netherlands remaining possible (art. 12aa-3). Based on the corporate income tax return and the returns/assessments of a tax on profit of the foreign head office, you may be able to determine that the revenue is included in taxation in both States. You can also have your tax advisor, in cooperation with a foreign tax advisor, investigate whether there is dual inclusion income, for example in the form of a legal opinion. We recommend you to include these documents in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Deduction without inclusion (art. 12aa-1f)`,
    id: 17,
    advice: [
      {
        question: '33',
        answer: 'No',
        risk: `You have indicated that there are no deemed (notional) payments, therefore the risk for ATAD2 for this element is low.`,
        obligation: '-',
      },
      {
        question: '34',
        answer: 'Yes',
        risk: `As the deemed (notional) payments are included in taxation at the level of the head office or another permanent establishment, the risk for ATAD2 with regard to art. 12aa-1f is low.`,
        obligation: `We recommend you to have the 'recipient' confirm that the corresponding revenue is included in taxation. We recommend you to include this confirmation in your ATAD2 administration. `,
      },
      {
        question: '34',
        answer: 'No',
        risk: `"&NaamA&" runs the risk that expenses which are in principle deductible, may not be deducted in the Netherlands because the revenue is not recognised by the State of residence of the head office, or by another permanent establishment of the head office, based on local tax laws. The risk may be mitigated in cases of dual inclusion income (art. 12aa-3), in which case the expenses may still be (partially) deductible. Dual inclusion income means that income is included in taxation both at the level of the Dutch permanent establishment and the foreign head office or a permanent establishment of the foreign head office.`,
        obligation: `We recommend you to check whether there may be dual inclusion income. Based on the corporate income tax return of the permanent establishment and the corporate income tax returns/assessments of the foreign head office and the other permanent establishment, you may be able to determine that the revenue is included in taxation in multiple States. You can also have your tax advisor, together with a foreign tax advisor, investigate whether there is dual inclusion income, for example in the form of a legal opinion. We recommend you to include these documents in your ATAD2 administration.`,
      },
    ]
  },
  {
    element: `Deemed payment by another permanent establishment not recognized by Dutch permanent establishment (art. 12ab-1)`,
    id: 18,
    advice: [
      {
        question: '35',
        answer: 'No',
        risk: `There is no deemed (notional) payment by a foreign permanent establishment of a foreign head office to the Dutch permanent establishment of that same head office. Therefore, the risk for ATAD2 is low with regard to art. 12ab insofar as it concerns the deemed payment by a non-EU permanent establishment to a Dutch permanent establishment.`,
        obligation: `We recommend you to have the management of the head office/group confirm that there is no deemed payment to the Dutch permanent establishment. We recommend you to include this confirmation in your ATAD2 administration.`,
      },
      {
        question: '35',
        answer: 'Yes',
        risk: `"&NaamA&" runs the ATAD2 risk that, based on Dutch tax law, the unrecognised revenue must be included in Dutch taxation. You have indicated that there is a deemed payment by a non-EU permanent establishment of the foreign head office to the Dutch permanent establishment of the foreign head office. If the corresponding revenue is not recognised in the Netherlands, and no regulations comparable to ATAD2 have been implemented in the State of the head office or that of the other permanent establishment, you are obliged to include the revenue in the corporate income tax return of the permanent establishment based on art. 12ab-1.`,
        obligation: `We recommend you to investigate whether the corresponding revenue is indeed not recognised in the Netherlands and whether regulations comparable to ATAD2 have been implemented in the State of the head office or that of the other permanent establishment. We recommend you to include this analysis in your ATAD2 administration.`,
      },
    ]
  },
];

